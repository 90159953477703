import React from "react";
import Footer from "src/components/Footer_uk";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          src="//tt.mbww.com/tt-b028e7b30ae6629575651e0369e15c482a1b97a7d437334f1b1cf00f5c06a78f.js"
          async
        />
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/uk/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/uk/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/uk/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/allergy/">Allergy</a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">After sun</a>
                      </li>                      
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/where-to-buy/" target="_self">
                      Where to buy
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/legal-notice/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/legal-notice/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/aviso-legal/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/aviso_legal/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/legal-notice/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/legal-notice/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h1>LEGAL NOTICE</h1>
                <p>
                  <strong>All content</strong> of this Internet site is owned or
                  controlled by Johnson &amp; Johnson Limited and/or JNTL Consumer Health I (Ireland) Limited and is protected by worldwide
                  copyright laws. You may download content only for your
                  personal use for non-commercial purposes but no modification
                  or further reproduction of the content is permitted. The
                  content may otherwise not be copied or used in any way.
                  <br />
                  <br />
                  <strong>The owners</strong> of this site will use reasonable
                  efforts to include up-to-date and accurate information in this
                  Internet site, but make no representations, warranties or
                  assurances as to the accuracy, currency or completeness of the
                  information provided. The owners of this site shall not be
                  liable for any damages or injury resulting from your access
                  to, or inability to access, this Internet site, or from your
                  reliance on any information provided at this Internet site. It
                  is intended for a UK and Republic of Ireland audience.
                  <br />
                  <br />
                  <strong>This Internet site</strong> may provide links or
                  references to other sites but the owners of this site have no
                  responsibility for the content of such other sites and shall
                  not be liable for any damages or injury arising from that
                  content. Any links to other sites are provided merely as a
                  convenience to the users of this Internet site.
                  <br />
                  <br />
                  <strong>The trademarks</strong>, service marks, trade names,
                  trade dress and products in this Internet site are protected
                  in the UK, Republic of Ireland and internationally. No use of
                  any of these may be made without the prior, written
                  authorisation of the owners of this site, except to identify
                  the products or services of the company.
                  <br />
                  <br />
                  <strong>Any personally</strong> identifiable information in
                  electronic communications to this Internet site is governed by
                  this site's{" "}
                  <a href="/uk/legal/private-policy/">Privacy Policy</a>. The
                  owners of this site shall be free to use or copy all other
                  information in any such communications including any ideas,
                  inventions, concepts, techniques or know-how disclosed
                  therein, for any purposes. Such purposes may include
                  disclosure to third parties and/or developing, manufacturing
                  and/or marketing goods or services.
                  <br />
                  <br />
                  The sender of any communications to this internet site or
                  otherwise to the owners of this site shall be responsible for
                  the content and information contained therein, including its
                  truthfulness and accuracy.
                  <br />
                  <br />
                  <br />
                  <br />
                  <em>Company Information</em>
                  <br />
                  <br />
                  <strong>Johnson &amp; Johnson Limited</strong>
                  <br />
                  50 - 100 Holmers Farm Way
                  <br />
                  High Wycombe
                  <br />
                  HP12 4EG
                  <br />
                  United Kingdom
                  <br />
                  Company Registered Number: 02175750
                  <br />
                  <br />
                  <strong>JNTL Consumer Health I (Ireland)</strong>{" "}
                  <strong>Limited</strong>
                  <br />
                  Limited Airton Road, Tallaght
                  <br />
                  Dublin 24
                  <br />
                  Company Registered Number: 9043
                </p>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.instagram.com/PizBuin_UK/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n/* <![CDATA[ */\nvar google_conversion_id = 924078965;\nvar google_custom_params = window.google_tag_params;\nvar google_remarketing_only = true;\n/* ]]> */\n"
          }}
        />
        <script
          type="text/javascript"
          src="//www.googleadservices.com/pagead/conversion.js"
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <div style="display:inline">\n            <img height="1" width="1" style="border-style:none" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/924078965/?value=0&amp;guid=ON&amp;script=0">\n         </div>\n      '
          }}
        />
      </div>
    );
  }
}

export default Page;
