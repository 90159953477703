/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gtm from "src/components/Gtm";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="uk" />
          <body className="desktop pageGeneric" id="pageLegal" />
          <meta charSet="UTF-8" />
          <meta name="Keywords" content />
          <meta
            content="width=device-width,initial-scale=1, minimum-scale=0.5, maximum-scale=1.0"
            name="viewport"
          />
          <title>Piz Buin</title>
          <link
            href="//fonts.googleapis.com/css?family=Open+Sans:400,800,700,600,300&subset=latin"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="//fast.fonts.net/cssapi/a23edeb8-5888-4291-b2f7-2b67a0b2708d.css"
            type="text/css"
            rel="stylesheet"
          />
          <link
            type="text/css"
            href="/assets/css/styles.css"
            rel="stylesheet"
          />
          <link
            type="text/css"
            href="/assets/css/vendor/iealert.css"
            rel="stylesheet"
          />
          <script
            type="text/javascript"
            innerHTML="
var lang = 'uk';
"
          />
          <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
          <link
            rel="alternate"
            hrefLang="en-Uk"
            href="https://www.pizbuin.com/uk/legal/legal-notice/"
          />
          <title>Legal Notice Information | PIZ BUIN®</title>
          <meta
            name="description"
            content="The PIZ BUIN® Legal Notice contains legal information covering the use and liabilities of the pizbuin.com website. Learn more here"
          />
          <meta
            name="keywords"
            content="PIZ BUIN,
   pizbuin,
   piz-buin,
   sun,
   legal notice,
   sun protection,
   sunprotection,
   protection,
   sun screen,
   sunscreen,"
          />
          
          <link
            rel="shortcut icon"
            href="/assetsassets/icons/favicon.ico"
            type="image/x-icon"
          />
          <link
            rel="icon"
            href="/assetsassets/icons/favicon.ico"
            type="image/x-icon"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/assets/css/vendor/ccm.base.css"
          />
         <script type="text/javascript" src="/assets/js/vendor/jquery.js" />  

<script type="text/javascript" src="/assets/js/vendor/jquery-migrate-3.0.0.min.js" />
          <script type="text/javascript" src="/assets/js/vendor/ccm.base.js" />
          <script type="text/javascript">
function OptanonWrapper() { }
</script>
          
          <script
            innerHTML="
!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
document,'script','https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1729350314016047');
fbq('track', &quot;PageView&quot;);
"
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '\n         <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1729350314016047&amp;ev=PageView&amp;noscript=1" />\n      '
            }}
          />
        </Helmet>
        <Gtm />
        ;
      </React.Fragment>
    );
  }
}
